<template>
  <div class="relative min-h-screen font-satoshi text-xl">
    <AppNavbar />
    <div class="h-20 md:h-22" />
    <main role="main">
      <slot />
    </main>
    <AppFooter />
  </div>
</template>
