<template>
  <nav class="container fixed inset-x-0 top-0 z-10 !max-w-container pt-4 md:pt-6">
    <div
      class="flex flex-col rounded-[32px] bg-white p-2 transition-all max-md:h-16"
      :class="{'max-md:!h-[calc(100vh_-_2rem)]': mobileMenu}"
    >
      <div class="flex items-center justify-between gap-x-10">
        <NuxtLink to="/">
          <NuxtPicture
            src="/logo.svg"
            alt="Say BTTR Logo"
            :img-attrs="{class: 'w-28 md:w-32'}"
          />
        </NuxtLink>
        <ul class="hidden items-center gap-x-2 text-lg font-medium leading-6 transition-all md:flex">
          <li
            v-for="(item, index) in menuItems"
            :key="index"
          >
            <NuxtLink
              :to="item.to"
              class="block rounded-4xl p-2 hover:bg-gray [&.router-link-active]:bg-gray"
            >
              {{ item.label }}
            </NuxtLink>
          </li>
        </ul>
        <UiButton
          variant="secondary"
          size="small"
          icon-name="mdi:plus"
          to="/tool"
          class="max-md:hidden"
          label="Probeer de tool"
        />
        <button
          type="button"
          class="flex size-12 items-center justify-center rounded-4xl border border-black bg-white text-2xl md:hidden"
          aria-label="Toggle Menu"
          @click="toggleMenu"
        >
          <Transition
            name="fade"
            mode="out-in"
          >
            <Icon
              :key="mobileMenu ? 'close' : 'menu'"
              :name="mobileMenu ? 'mdi:close' : 'mdi:menu'"
            />
          </Transition>
        </button>
      </div>
      <UiExpander :open="mobileMenu">
        <ul class="mt-16 flex flex-col gap-y-2 text-lg font-medium md:hidden">
          <li
            v-for="(item, index) in menuItems"
            :key="index"
          >
            <NuxtLink
              :to="item.to"
              class="flex items-center justify-center rounded-4xl p-2 hover:bg-gray [&.router-link-active]:bg-gray"
            >
              {{ item.label }}
            </NuxtLink>
          </li>
          <li>
            <NuxtLink
              :to="{name: 'tool'}"
              class="flex items-center justify-center rounded-4xl p-2 hover:bg-gray [&.router-link-active]:bg-gray"
            >
              Probeer de tool
            </NuxtLink>
            <UiButton
              variant="secondary"
              size="small"
              icon-name="mdi:plus"
              to="/tool"
              class="max-md:hidden"
              label="Probeer de tool"
            />
          </li>
        </ul>
      </UiExpander>
    </div>
  </nav>
</template>

<script setup lang="ts">
const menuItems = [
  {label: 'Product', to: '/'},
  {label: 'Sessions', to: '/sessions'},
  {label: 'Dataveiligheid', to: '/dataveiligheid'},
];

const mobileMenu = ref(false);

function toggleMenu() {
  mobileMenu.value = !mobileMenu.value;
}

const router = useRouter();

router.afterEach(() => {
  mobileMenu.value = false;
});
</script>

<style scoped>
.fade-enter-active, .fade-leave-active {
  @apply transition-all duration-200;
}

.fade-enter, .fade-leave-to {
  @apply opacity-0;
}
</style>
