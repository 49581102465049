<template>
  <footer class="bg-gray-900 py-20 text-white">
    <div class="container flex flex-col gap-y-32 ">
      <div class="flex gap-5 max-lg:flex-col lg:items-end lg:justify-between">
        <h2 class="max-w-xl">
          Connect je eigen GA4 property en say BTTR tegen je data
        </h2>
        <UiButton
          :to="{name: 'tool'}"
          icon-position="left"
          label="Start met innovatie"
        />
      </div>
      <div class="flex flex-col gap-y-20">
        <div class="flex max-w-sm flex-col gap-y-5">
          <NuxtPicture
            src="/logo-alt.svg"
            alt="Say BTTR Logo Alt"
            :img-attrs="{class: 'w-32'}"
          />
          <p>
            Say BTTR is ontwikkeld door digital agency BTTR.
            Bij BTTR werken we met onze teams aan digitale ervaringen voor mensen en organisaties. Onderdeel van
            <NuxtLink
              class="border-b"
              to="https://www.bttr.nl"
              target="_blank"
            >
              bttr.nl
            </NuxtLink>
          </p>
        </div>
        <ul class="flex w-full flex-wrap items-center justify-center gap-x-6">
          <li
            v-for="(item, index) in menuItems"
            :key="index"
          >
            <NuxtLink
              :to="item.to"
              class="link-underline text-base text-white"
              :target="item.target"
            >
              {{ item.label }}
            </NuxtLink>
          </li>
        </ul>
      </div>
    </div>
  </footer>
</template>

<script setup lang="ts">
const menuItems = [
  {label: 'Contact', to: 'https://bttr.nl/contact', target: '_blank'},
  {label: 'Over ons', to: 'https://bttr.nl/over-ons', target: '_blank'},
  {label: 'Sessions', to: 'sessions'},
  {label: 'Tool', to: 'chat'},
  {label: 'Dataveiligheid', to: 'dataveiligheid'},
  {label: 'Privacybeleid', to: 'privacybeleid'},
];
</script>
